import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import QRCode from "react-qr-code";

import { clearAlerts } from "../store/alert/alertSlice";
import { SOCIAL_PLATFORMS_SVG } from "../constants/constants";
import { postDataAPI } from "../utils/fetchData";
import { update_user_article_thunk } from "../store/user/userThunk";
import Check from "../svg/Check";
import CopyLink from '../svg/CopyLink';

const Loading = () => {
  const [content, setContent] = useState("Publishing...");

  useEffect(() => {
    setTimeout(() => {
      setContent("Deploying Hypersite...");
    }, 3000);
  }, []);
  return (
    <div className="text-[12px] font-[410] text-white leading-[14.16px]">
      {content}
    </div>
  );
};

const QR = ({ setShowPublish, userArticle }) => {
  const getPlatformSrc = (value) => {
    const platform = SOCIAL_PLATFORMS_SVG.find((p) => p.value === value);
    if (!platform) return null;

    return {
      Component: platform.Component,
      redirect: platform.redirect,
    };
  };
  const handleSocialClick = async (redirect, handle, key) => {
    const url = `${redirect}${handle}`;
    window.open(url, "_blank");
  };
  return (
    <div>
      <div className="text-[12px] font-[410] leading-[14.16px] w-full mb-[120px] flex justify-between">
        <div>Share your Hypersite</div>
        <div>
          <span
            onClick={() => setShowPublish(false)}
            className="cursor-pointer"
          >
            Done
          </span>
        </div>
      </div>

      <div className="w-full text-center text-white text-[12px] mt-4 mb-4">
        diz.ee/{userArticle?.domain}
      </div>
      <div className="w-[325px] h-[325px] rounded-xl bg-[#FF2300] pt-[65px] px-[65px]">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          bgColor="#FF2300"
          fgColor="#ffffff"
          value={`https://diz.ee/${userArticle?.domain}`}
        />
      </div>
      <div className="w-[325px] flex justify-between px-[10px] mt-2">
        <div className="flex flex-row mt-2  space-x-2 ">
          {userArticle?.social &&
            Object.keys(userArticle.social).map((key) => {
              const platformData = getPlatformSrc(key);

              if (platformData && platformData.Component) {
                const SocialIcon = platformData.Component;
                return (
                  <div
                    key={key}
                    className="h-[15px] w-[15px]cursor-pointer"
                    onClick={() =>
                      handleSocialClick(
                        platformData.redirect,
                        userArticle.social[key],
                        key
                      )
                    }
                  >
                    <SocialIcon
                      className="w-[15px] h-[15px] cursor-pointer"
                      color={"#FBFBFD"}
                    />
                  </div>
                );
              }
              return null;
            })}
        </div>
        <div className="flex items-center cursor-pointer">
          <div className="text-[12px] font-[410] leading-[14.16px]">Share</div>
          <div>
            <CopyLink className="h-[14px] w-[14px] mx-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default function PublishOverlay({ setShowPublish }) {
  const token = localStorage.getItem("dizeeToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserArticle = useSelector((state) => state.user.userArticle);

  const [publish, setPublish] = useState({
    staging:
      currentUserArticle?.publish?.staging === undefined
        ? false
        : currentUserArticle?.publish?.staging,
    production:
      false || currentUserArticle?.publish?.production === undefined
        ? false
        : currentUserArticle?.publish?.production,
  });
  const [showLoading, setShowLoading] = useState(false);
  const [showQr, setShowQr] = useState(false);

  const getPlatformSrc = (value) => {
    const platform = SOCIAL_PLATFORMS_SVG.find((p) => p.value === value);
    if (!platform) return null;

    return {
      Component: platform.Component,
      redirect: platform.redirect,
    };
  };

  const handleSocialClick = async (redirect, handle, key) => {
    const url = `${redirect}${handle}`;
    window.open(url, "_blank");

    try {
      await postDataAPI(
        "analytics/create-socialfeed-analytics",
        { platform: key, value: handle, domain: currentUserArticle?.domain },
        null
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === "overlay") {
      setShowPublish(false);
    }
  };

  const onPublish = () => {
    dispatch(
      update_user_article_thunk({
        token,
        body: { ...currentUserArticle, publish: publish },
      })
    );
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
      setShowQr(true);
    }, 6000);
  };

  return (
    <div
      id="overlay"
      className="fixed inset-0 bg-black  flex justify-center items-center z-20"
      onClick={handleOverlayClick}
    >
      <div className="h-[100vh] w-full text-white flex justify-center items-center">
        {!showLoading && !showQr && (
          <div>
            <div className="text-[12px] font-[410] leading-[14.16px] w-full mb-[120px] flex justify-between">
              <div>Select where to publish</div>
              <div>
                <span className="cursor-pointer mr-3" onClick={onPublish}>
                  Publish
                </span>
                <span
                  onClick={() => setShowPublish(false)}
                  className="cursor-pointer"
                >
                  Exit
                </span>
              </div>
            </div>
            <div className="w-[325px] h-[325px] rounded-xl ">
              <div className="flex items-center justify-start text-[12px] font-[410] leading-[14.16px]">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    setPublish({ ...publish, staging: !publish.staging })
                  }
                >
                  Staging
                </div>
                {publish?.staging && (
                  <Check className="h-[12px] w-[18px] mt-1 ml-2" />
                )}
              </div>
              <div className="text-[#585858] text-[12px] font-[410] leading-[14.16px]">
                diz.ee/staging/{currentUserArticle?.domain}
              </div>
              <div className="flex items-center justify-start text-[12px] font-[410] leading-[14.16px] mt-[30px]">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    setPublish({ ...publish, production: !publish.production })
                  }
                >
                  Production
                </div>
                {publish?.production && (
                  <Check className="h-[12px] w-[18px] mt-1 ml-2" />
                )}
              </div>
              <div className="text-[#585858] text-[12px] font-[410] leading-[14.16px]">
                diz.ee/{currentUserArticle?.domain}
              </div>
            </div>
            <div className="w-[325px] flex justify-between px-[10px]">
              <div className="flex flex-row mt-2  space-x-2 ">
                {currentUserArticle?.social &&
                  Object.keys(currentUserArticle.social).map((key) => {
                    const platformData = getPlatformSrc(key);

                    if (platformData && platformData.Component) {
                      const SocialIcon = platformData.Component;
                      return (
                        <div
                          key={key}
                          className="h-[15px] w-[15px]cursor-pointer"
                          onClick={() =>
                            handleSocialClick(
                              platformData.redirect,
                              currentUserArticle.social[key],
                              key
                            )
                          }
                        >
                          <SocialIcon
                            className="w-[15px] h-[15px] cursor-pointer"
                            color={"#FBFBFD"}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          </div>
        )}
        {showLoading && !showQr && <Loading />}

        {showQr && (
          <QR
            userArticle={currentUserArticle}
            setShowPublish={setShowPublish}
          />
        )}
      </div>
    </div>
  );
}
