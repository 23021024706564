import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearAlerts } from '../store/alert/alertSlice';
import { SkeletonBlock, SkeletonImage, SkeletonText, SkeletonAvatar } from "skeleton-elements/react";
import 'skeleton-elements/css/skeleton-elements.css';

const Alert = () => {
    const dispatch = useDispatch()
    const alert = useSelector((state) => state.alert);
    useEffect(() => {
        if (alert?.error) {
            toast.error(alert?.error, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                dispatch(clearAlerts());
            }, 2500);
        }

        if (alert?.success) {
            toast.success(alert?.success, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                dispatch(clearAlerts());
            }, 2500);
        }
    }, [alert]);

    return (
        <>
            {
                alert?.loading &&
                <>
                    
                    <div className='flex items-center justify-center z-20 w-screen h-screen '>
                        <div className='bg-[#000000] bg-opacity-90 h-screen grid grid-cols-1 gap-5 content-center items-center flex'>
                                <SkeletonAvatar tag='div' width="80%" effect='wave' />
                                <SkeletonBlock tag="p" width="280px" effect={'wave'} />
                                <SkeletonBlock tag="p" width="100px" effect={'wave'} />
                                <SkeletonBlock tag="p" width="200px" effect={'wave'} />
                                <SkeletonImage width={'200px'} height={'100px'} />
                                <SkeletonText tag="p" width="240px" effect='wave' />
                                <SkeletonBlock tag="p" width="300px" effect={'wave'} />
                                <SkeletonBlock tag="p" width="230px" effect={'wave'} />
                        </div>
                        
                    </div>
                </>

            }

            <ToastContainer className={'z-50'} />
        </>
    );
};

export default Alert;
