import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import QRCode from "react-qr-code";

import { clearAlerts } from '../store/alert/alertSlice';
import { get_domain_articles_thunk } from '../store/domain/domainThunk';
import { SOCIAL_PLATFORMS_SVG } from '../constants/constants';
import { postDataAPI } from '../utils/fetchData';
import CopyLink from '../svg/CopyLink';
import { getContrastColor } from '../components/DixeeInput2'
import { follow_unfollow_thunk } from '../store/user/userThunk';

export default function QrOverlay({ setShowQr }) {
    const { domain: dynamicVar } = useParams();
    const token = localStorage.getItem('dizeeToken');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userArticle = useSelector((state) => state.domain.userArticle);
    const currentUserArticle = useSelector((state) => state.user.userArticle);

    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        if (dynamicVar) {
            dispatch(clearAlerts());
            dispatch(get_domain_articles_thunk({ domain: dynamicVar }));
        }
    }, [dispatch, dynamicVar]);

    const getPlatformSrc = (value) => {
        const platform = SOCIAL_PLATFORMS_SVG.find(p => p.value === value);
        if (!platform) return null;

        return {
            Component: platform.Component,
            redirect: platform.redirect,
        };
    };

    const handleSocialClick = async (redirect, handle, key) => {
        const url = `${redirect}${handle}`;
        window.open(url, '_blank');

        try {
            await postDataAPI('analytics/create-socialfeed-analytics', { platform: key, value: handle, domain: dynamicVar }, null);
        }
        catch (err) {
            console.log(err)
        }
    };

    const handleFollowUnfollow = async () => {
        if (token === null || token === undefined || token.length === 0)
            navigate("/");
        dispatch(follow_unfollow_thunk({ domain: dynamicVar, token }))
            .then((res) => {
                if (res?.payload?.status === 200) {
                    
                    setIsFollowing(!isFollowing);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setShowQr(false);
        }
    };
    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            <div className='h-[100vh] w-full text-white flex justify-center items-center'>
                <div>
                    <div className='text-[12px] font-[410] leading-[14.16px] w-full text-right mb-[120px]'><span onClick={() => setShowQr(false)} className='cursor-pointer'>Go back</span></div>
                    <div className='w-[325px] h-[325px] rounded-xl bg-[#FF2300] pt-[65px] px-[65px]'>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            bgColor='#FF2300'
                            fgColor='#ffffff'
                            value={`https://diz.ee/${dynamicVar}`}
                        />
                        <div className='w-full text-center text-white text-[12px] tracking-[5px] mt-4'>diz.ee/{dynamicVar}</div>
                    </div>
                    <div className='w-[325px] flex justify-between px-[10px]'>
                        <div className='flex flex-row mt-2  space-x-2 '>
                            {userArticle?.social && Object.keys(userArticle.social).map(key => {
                                const platformData = getPlatformSrc(key);

                                if (platformData && platformData.Component) {
                                    const SocialIcon = platformData.Component;
                                    return (
                                        <div
                                            key={key}
                                            className='h-[15px] w-[15px]cursor-pointer'
                                            onClick={() => handleSocialClick(platformData.redirect, userArticle.social[key], key)}
                                        >
                                            <SocialIcon className="w-[15px] h-[15px] cursor-pointer" color={"#FBFBFD"} />
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className='flex items-center cursor-pointer'>
                            <div className='text-[12px] font-[410] leading-[14.16px]'>Share</div>
                            <div><CopyLink className='h-[14px] w-[14px] mx-1' /></div>
                        </div>
                    </div>

                    <div className='flex justify-between mt-[140px] items-center'>
                        <div className='text-[10px] font-[400] leading-[14.32px]' >Follow {userArticle?.username} and other creators on Dizee</div>
                        <div>
                            <button className={`text-white text-[12px] font-[410] leading-[14.16px] rounded px-[10px] py-[3px] ${(isFollowing) ? "border border-white" : "bg-[#FF2300]"}`} style={getContrastColor(userArticle?.background) ? { color: `#${getContrastColor(userArticle?.background)}` } : { color: '#ffffff' }} onClick={handleFollowUnfollow}>
                                {(isFollowing) ? 'Unfollow' : 'Follow'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}